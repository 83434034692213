<template>
  <div id="tables-screen" translate="no">
    <Loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :height="40"
      loader="spinner"
      color="#003340"
    ></Loading>
    <BackToServices
      :table="tableId"
      :storeName="storeName"
      :isApplication="isApplication"
      :hasBoxShadow="true"
      :amountOfDepartments="2"
      :restaurantType="'OPENINGS'"
      id="back-to-services"
    ></BackToServices>
    <div id="banner">
      <img v-if="store" id="banner-image" :src="`${store.bannerLink}`" alt />

      <div id="store-info">
        <Store id="store" :store="store" :shouldShowMenu="false"></Store>
      </div>

      <div id="upper-bar">
        <div id="logo-container" v-if="!isApplication">
          <a id="link-to-landing" href="/">
            <img id="logo" src="../../assets/Full_logo-Black.svg" alt />
          </a>
        </div>
        <div id="profile-and-cart">
          <LanguageSelect
            v-if="isAbleToChangeLanguage"
            id="language-select"
            :class="{ 'language-position-in-login': isLoggedIn }"
          ></LanguageSelect>

          <ProfileDropdown
            v-if="isLoggedIn"
            id="profile-dropdown"
            :isApplication="isApplication"
            :class="{ 'profile-dropdown-with-lang-select': isAbleToChangeLanguage,
                    'profile-dropdown-without-lang-select': !isAbleToChangeLanguage }"
          ></ProfileDropdown>
          <EnterPrompt
            v-else-if="shouldShowEnterPrompt && !isLoggedIn && !isApplication"
            @enter-clicked="initiateEntrance"
            id="enter-prompt"
            :class="{ 'enter-prompt-with-lang-select': isAbleToChangeLanguage,
                    'enter-prompt-without-lang-select': !isAbleToChangeLanguage }"
          ></EnterPrompt>
        </div>
      </div>
    </div>
    <div class="tables-container" v-if="!isLoading">
      <div id="select-department-container">
        <div class="label-and-name" v-if="restaurantsWithTableOpenings.length>1">
          <div class="label">
            <h6
              class="department-title"
              :class="{ 'no-bottom-margin': sections.length>0 }"
            >{{labels.tableOpenings.department[language]}}</h6>
            <select
              v-model="selectedDepartmentId"
              :class="{ 'no-bottom-margin': sections.length>0 }"
              @change="selectDepartment()"
            >
              <option
                v-for="restaurant in restaurantsWithTableOpenings"
                :key="restaurant.id"
                :value="restaurant.id"
              >{{restaurant.names.translation[language].name }}</option>
            </select>
          </div>
        </div>

        <div class="label-and-name">
          <div
            class="label"
            style="margin-right:10px"
            v-if="!tableTypesToFetchOpenings.includes('HOTEL_ROOM')"
          >
            <h6
              class="department-title no-bottom-margin"
            >{{labels.tableOpenings.typeOfTable[language]}}:</h6>
            <select
              @change="onTypeOfTableOpeningChange"
              class="no-bottom-margin"
              v-model="typeOfTableOpenings"
            >
              <option value="TABLES">{{labels.table.tables[this.language]}}</option>
              <option value="SELF_SERVICE">{{labels.table.selfService[this.language]}}</option>
            </select>
          </div>
          <div class="label">
            <h6 class="department-title no-bottom-margin">{{labels.tableOpenings.search[language]}}</h6>
            <input class="search-tables-input" v-model="tableToSearch" placeholder="πχ Τ-02" />
          </div>
        </div>

        <div id="sections-container" v-if="sections.length>0">
          <button
            @click="setSelectedSection('ALL')"
            class="section-selection-button"
            :class="{ 'selected-section': selectedSection=='ALL' }"
          >Όλα</button>
          <button
            v-for="(section, index) in sections"
            :key="index"
            :value="section"
            @click="setSelectedSection(section)"
            class="section-selection-button"
            :class="{ 'selected-section': selectedSection==section }"
          >{{section}}</button>
          <button
            @click="setSelectedSection(null)"
            class="section-selection-button"
            :class="{ 'selected-section': selectedSection==null }"
          >Υπόλοιπα</button>
        </div>

        <div class="label-and-name" style="margin-top: 7px !important">
          <div class="label">
            <h6
              class="open-tables-details-title"
              style="margin-bottom: 0px"
            >{{labels.tableOpenings.totalOfOpen[language]}} {{tableOpeningsToShowTotal.toFixed(2)}}€</h6>

            <button
              v-if="!tableTypesToFetchOpenings.includes('HOTEL_ROOM')"
              id="add-new-order-button"
              @click="goToPdaMenu()"
            >
              Μενού
              <font-awesome-icon icon="list-alt" />
            </button>

            <h6
              v-if="!tableTypesToFetchOpenings.includes('HOTEL_ROOM') && typeOfTableOpenings=='TABLES'"
              @click="toggleShowOnlyOpenTables()"
              class="open-tables-details-title"
              style="margin-bottom: 0px; margin-left:6px"
            >Μόνο ανοιχτά</h6>

            <label
              class="switch"
              v-if="!tableTypesToFetchOpenings.includes('HOTEL_ROOM') && typeOfTableOpenings=='TABLES'"
            >
              <input type="checkbox" v-model="showOnlyOpenTables" />
              <span class="slider round"></span>
            </label>
          </div>
        </div>
      </div>

      <div id="table-items" v-if="tableOpeningsToShow.length > 0">
        <div
          class="table-opening"
          v-for="tableOpening in tableOpeningsToShow"
          :class="{ 'just-closed-table': !tableOpening.currentlyOpen && tableOpening.id!=null,'open-table': tableOpening.currentlyOpen, 'blue-table': tableOpening.currentlyOpen && tableOpening.minimumConsumption>0 && tableOpening.totalPrice>=tableOpening.minimumConsumption }"
          :key="tableOpening.id"
          @click="openTableOrders(tableOpening)"
        >
          <h5
            class="table-opening-table-header"
            :class="{ 'no-bottom-margin': sections.length>0, 'dark-table-header' : !tableOpening.currentlyOpen }"
          >
            <font-awesome-icon
              v-if="tableOpening.table.type == 'BEACH_CHAIR' || tableOpening.table.type == 'UMBRELLA_AT_HOTEL'"
              icon="umbrella-beach"
              style="margin-left: 2px;"
            />
            <font-awesome-icon
              v-else-if="tableOpening.table.type=='TABLE' || tableOpening.table.type=='TABLE_AT_HOTEL'"
              icon="chair"
              style="margin-left: 2px;"
            />
            <font-awesome-icon
              v-else-if="tableOpening.table.type=='HOTEL_ROOM'"
              icon="bed"
              style="margin-left: 2px;"
            />
            <!-- {{ tableOpening.table.type=='TABLE' || tableOpening.table.type=='TABLE_AT_HOTEL' ? labels.tableOpenings.tableHeader[language] : tableOpening.table.type=='BEACH_CHAIR' || tableOpening.table.type=='UMBRELLA_AT_HOTEL' ? labels.tableOpenings.beachChairHeader[language] : '' }} -->
            {{tableOpening.table.tableNumber}} {{tableOpening.alias}}
          </h5>
          <h5
            v-if="sections.length>0"
            class="table-opening-section-header"
          >{{tableOpening.table.familySection!=null ? tableOpening.table.familySection : 'Υπόλοιπα'}}</h5>
          <hr />
          <h5
            v-if="tableOpening.id!=null "
            class="table-opening-table-total"
          >{{labels.tableOpenings.totalCost[language]}} {{(tableOpening.totalPrice + tableOpening.amountTreated).toFixed(2)}}€</h5>
          <h5 class="table-opening-table-total" v-else>-</h5>
          <h5
            v-if="tableOpening.currentlyOpen"
            class="table-opening-remaining-total"
          >{{labels.tableOpenings.toBePaid[language]}} {{amountRemainingToBePaid(tableOpening)}}€</h5>
          <h5
            class="table-opening-remaining-total"
            v-else
          >{{!tableOpening.currentlyOpen && tableOpening.id!=null ? labels.tableOpenings.paidOff[language]: 'Κλειστό τραπέζι'}}</h5>
          <h6
            class="table-opening-table-date"
            v-if="tableOpening.openingDate!=null"
          >{{tableOpening.openingDate}}</h6>
          <h6 class="table-opening-table-date" v-else>-</h6>
        </div>
      </div>
      <div id="no-open-tables-message-container" v-else>
        <h5>{{labels.tableOpenings.noOpenTablesRightNow[language]}}</h5>
      </div>
    </div>
    <div
      @click="onMaskClick"
      :class="{
        mask:
          isEntranceOpen ||
          isLanguageModalOpen
      }"
    ></div>
    <PoweredByFooter v-if="!isLoading"></PoweredByFooter>
    <OrdersOfOpenTableModal
      v-bind:class="{ hide: !isTableOrdersModalOpen}"
      :tableOpening="selectedTableOpening"
      :loadingOrders="loadingOrders"
      :ordersOfSelectedTableOpening="ordersOfSelectedTableOpening"
      :tempReceiptsOfSelectedTableOpening="tempReceiptsOfSelectedTableOpening"
      :finalReceiptsOfSelectedTableOpening="finalReceiptsOfSelectedTableOpening"
      :ordersConcatedNote="ordersConcatedNote"
      :errorWhileLoadingOrders="errorWhileLoadingOrders"
      :restaurantsWithTableOpenings="restaurantsWithTableOpenings"
      :hotelRooms="hotelRooms"
      :isHotel="store && store.hotel"
      @reload-tables-screen="loadTablesScreen"
      @update-selected-tableOpening="updateSelectedTableOpening"
      @set-selected-tableOpening="setSelectedTableOpening"
      :authToken="authToken"
      :sections="sections"
      :jwt="jwt"
      :adminAccounts="adminAccounts"
    ></OrdersOfOpenTableModal>
  </div>
</template>

<script>
import Store from "../Store";
import StoreApi from "../../api/stores";
import UserApi from "../../api/user";
import OrderApi from "../../api/order";
import ProfileDropdown from "../ProfileDropdown";
import EnterPrompt from "../EnterPrompt";
import LanguageSelect from "../LanguageSelect";
import Button from "../Button";
import BackToServices from "../BackToServices.vue";
import OrdersOfOpenTableModal from "../OrdersOfOpenTableModal";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import _ from "lodash";

import CustomBottomBanner from "../CustomBottomBanner.vue";
import Footer from "../Footer.vue";
import PoweredByFooter from "../PoweredByFooter.vue";

export default {
  name: "TablesScreen",
  components: {
    Store,
    ProfileDropdown,
    EnterPrompt,
    Loading,
    LanguageSelect,
    Button,
    BackToServices,
    CustomBottomBanner,
    Footer,
    OrdersOfOpenTableModal,
    PoweredByFooter
  },
  props: [""],
  data() {
    return {
      store: undefined,
      isLoading: true,
      from: undefined,
      fromRouteName: '',
      shouldShowEnterPrompt: false,
      isApplication: false,
      isAbleToChangeLanguage: true,
      restaurantsWithTableOpenings: [],
      sections: [],
      selectedSection: "ALL",
      typeOfTableOpenings: "TABLES",
      selectedDepartmentId: 0,
      tableOpenings: [],
      selectedTableOpening: null,
      loadingOrders: false,
      errorWhileLoadingOrders: false,
      hotelRooms: [],
      ordersOfSelectedTableOpening: [],
      tempReceiptsOfSelectedTableOpening: [],
      finalReceiptsOfSelectedTableOpening: [],
      ordersConcatedNote: "",
      tableTypesToFetchOpenings: [],
      tableToSearch: null,
      showOnlyOpenTables: false,
    };
  },
  watch: {
    isTableOrdersModalOpen: function (newVal, oldVal) {
      let htmlElement = document.querySelector("html");
      if (newVal == true) {
        htmlElement.style.overflow = "hidden";
      } else {
        htmlElement.style.overflow = "auto";
      }
    }
  },
  created() {
  },
  async mounted() {
    if (this.$route.name == 'open-rooms') {
      this.tableTypesToFetchOpenings.push("HOTEL_ROOM");
    } else {
      this.tableTypesToFetchOpenings = ["TABLE", "BEACH_CHAIR", "TABLE_AT_HOTEL", "UMBRELLA_AT_HOTEL", "DELIVERY", "DELIVERY_TABLE"];
    }
    await this.loadTablesScreen();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.fromRouteName = from.name;
      vm.from = from;
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.isEntranceOpen) {
      this.closeEntrance();
      //below is needed for same reason as above
      window.history.scrollRestoration = "manual";
      next(false);
    } else if (this.isLanguageModalOpen) {
      this.$store.dispatch("closeLanguageModal");
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else if (this.isTableOrdersModalOpen) {
      this.$store.dispatch("closeTableOrdersModal");
      //below is needed as if route is the same as before after next(false)
      //browser autoscrolls to top of page
      window.history.scrollRestoration = "manual";
      next(false);
    } else {
      next();
    }
  },
  beforeDestroy() {
    if (this.selectedDepartmentId != this.restaurantsWithTableOpenings[0].id) {
      this.$store.dispatch("setTablesWithOpenings", [])
    }
  },
  computed: {
    tableId() {
      return this.$store.getters.getTableId;
    },

    tablesWithOpenings() {
      return this.$store.getters.getTablesWithOpenings;
    },

    tableOpeningsToShow() {
      if (this.selectedSection == 'ALL') {
        if (this.tableToSearch == null || this.tableToSearch.trim().length == 0) {
          if (this.showOnlyOpenTables) {
            let tableOpeningsToShow = this.tableOpenings.filter(tableOpening => tableOpening.currentlyOpen);
            return tableOpeningsToShow;
          }
          return this.tableOpenings;
        } else {
          let filteredTables = this.tableOpenings.filter(tableOpening => {
            if (tableOpening.alias != null && tableOpening.alias.trim().length > 0) {
              if (this.showOnlyOpenTables) {
                return tableOpening.alias.toUpperCase().trim().startsWith(this.tableToSearch.toUpperCase().trim()) && tableOpening.currentlyOpen;
              }
              return tableOpening.alias.toUpperCase().trim().startsWith(this.tableToSearch.toUpperCase().trim());
            } else {
              if (this.showOnlyOpenTables) {
                return tableOpening.table.tableNumber.toUpperCase().trim().startsWith(this.tableToSearch.toUpperCase().trim()) && tableOpening.currentlyOpen;
              }
              return tableOpening.table.tableNumber.toUpperCase().trim().startsWith(this.tableToSearch.toUpperCase().trim());
            }
          });
          return filteredTables;
        }
      } else {
        if (this.tableToSearch == null || this.tableToSearch.trim().length == 0) {
          if (this.showOnlyOpenTables) {
            let tableOpeningsToShow = this.tableOpenings.filter(tableOpening => tableOpening.table.familySection == this.selectedSection && tableOpening.currentlyOpen);
            return tableOpeningsToShow;
          }
          let tableOpeningsToShow = this.tableOpenings.filter(tableOpening => tableOpening.table.familySection == this.selectedSection);
          return tableOpeningsToShow;
        } else {
          let tableOpeningsToShow = this.tableOpenings.filter(tableOpening => {
            if (tableOpening.alias != null && tableOpening.alias.trim().length > 0) {
              if (this.showOnlyOpenTables) {
                return tableOpening.table.familySection == this.selectedSection && tableOpening.alias.toUpperCase().trim().startsWith(this.tableToSearch.toUpperCase().trim()) && tableOpening.currentlyOpen;
              }
              return tableOpening.table.familySection == this.selectedSection && tableOpening.alias.toUpperCase().trim().startsWith(this.tableToSearch.toUpperCase().trim());
            } else {
              if (this.showOnlyOpenTables) {
                return tableOpening.table.familySection == this.selectedSection && tableOpening.table.tableNumber.toUpperCase().trim().startsWith(this.tableToSearch.toUpperCase().trim()) && tableOpening.currentlyOpen;
              }
              return tableOpening.table.familySection == this.selectedSection && tableOpening.table.tableNumber.toUpperCase().trim().startsWith(this.tableToSearch.toUpperCase().trim());
            }

          });
          return tableOpeningsToShow;
        }
      }
    },

    tableOpeningsToShowTotal() {
      let totalOfOpenTables = 0;
      this.tableOpeningsToShow.forEach(tableOpening => {
        let totalPriceOfTableOpening = this.getTotalAmountOfTableOpening(tableOpening);
        totalOfOpenTables = totalOfOpenTables + totalPriceOfTableOpening;
      })

      return totalOfOpenTables;
    },

    isEntranceOpen() {
      return this.$store.getters.getSlideEntranceIn;
    },

    isTableOrdersModalOpen() {
      return this.$store.state.tableOrdersModalOpen;
    },

    isLoggedIn() {
      return this.$store.getters.isLoggedIn;
    },

    isLanguageModalOpen() {
      return this.$store.getters.isLanguageModalOpen;
    },

    language() {
      return this.$store.getters.getLanguage;
    },

    currentGreekDate() {
      return this.$store.getters.getCurrentGreekDate;
    },

    authToken() {
      return this.$store.getters.getUserAuthToken;
    },

    jwt() {
      return this.$store.getters.getUserJWT;
    },

    restaurantsWithTheirTables() {
      return this.$store.getters.getRestaurantsWithTheirTables;
    },

    adminAccounts() {
      return this.$store.getters.getAdminAccounts;
    },

    storeName() {
      if (this.store) {
        return this.store.names && this.store.names.translation[this.language]
          ? this.store.names.translation[this.language].name
          : this.store.name;
      }
    },

    title() {
      if (this.store) {
        return `${this.labels.titles.openTables[this.language]} - ${this.storeName} | Fluter`;
      } else {
        return `${this.labels.titles.openTables[this.language]} | Fluter`;
      }
    },
  },

  methods: {

    onMaskClick: function () {
      if (this.isEntranceOpen) {
        this.closeEntrance();
      } else if (this.isLanguageModalOpen) {
        this.$store.dispatch("closeLanguageModal");
      }
    },

    toggleShowOnlyOpenTables() {
      this.showOnlyOpenTables = !this.showOnlyOpenTables;
    },

    goToPdaMenu() {

      let selectedDepartment = this.restaurantsWithTableOpenings.find(restaurantWithOpenings => restaurantWithOpenings.id == this.selectedDepartmentId);
      let tableId = this.$router.currentRoute.params.tableId

      if (selectedDepartment && tableId) {
        this.$router.push({
          name: "menu",
          params: {
            tableId: tableId,
            service: selectedDepartment.routeName,
          }
        });
      } else {
        this.$confirm({
          message: `Κάτι πήγε λάθος. Δοκιμάστε να μεταβείτε στο μενού από την αρχική οθόνη.`,
          button: {
            yes: 'ΟΚ'
          },
          callback: async confirm => {
            if (confirm) {
            } else {
            }
          }
        });
      }

    },

    async onTypeOfTableOpeningChange() {
      if (this.typeOfTableOpenings == 'TABLES') {
        this.tableTypesToFetchOpenings = ["TABLE", "BEACH_CHAIR", "TABLE_AT_HOTEL", "UMBRELLA_AT_HOTEL", "DELIVERY", "DELIVERY_TABLE"];
      } else {
        this.tableTypesToFetchOpenings = ["QR_SELF_SERVICE", "SELF_SERVICE_POS"];
      }
      this.showOnlyOpenTables = false;
      this.selectedSection = "ALL";
      this.tableToSearch = null;
      await this.loadTablesScreen();
    },

    closeEntrance: function () {
      this.$store.dispatch("shouldSlideEntranceOut");

      this.$store.dispatch("shouldNotSlideEntranceIn");

      setTimeout(() => {
        this.$store.dispatch("resetEntranceState");
        this.$store.dispatch("setEntranceMessage", "");

        this.$store.dispatch("resetVerificationState");
        this.$store.dispatch("setVerificationMessage", "");
      }, 850);
    },

    initiateEntrance: function () {
      this.$store.dispatch("shouldSlideEntranceIn");
    },

    setSelectedSection(section) {
      this.selectedSection = section;
    },

    getTotalAmountOfTableOpening(tableOpening) {
      if (tableOpening.minimumConsumption == 0) {
        return tableOpening.totalPrice;
      }
      if (tableOpening.minimumConsumption > 0 && tableOpening.totalPrice == 0) {
        return 0;
      }
      let totalAmountToShow = tableOpening.totalPrice > tableOpening.minimumConsumption ? tableOpening.totalPrice : tableOpening.minimumConsumption;
      return totalAmountToShow;
    },

    amountRemainingToBePaid(tableOpening) {
      if (tableOpening.minimumConsumption == 0) {
        let amountThatRemains = tableOpening.totalPrice - tableOpening.amountPayedByCard - tableOpening.amountPayedByCash - tableOpening.amountChargedToRoom;
        amountThatRemains = amountThatRemains < 0 ? 0 : amountThatRemains;
        return (amountThatRemains).toFixed(2);
      }

      if (tableOpening.minimumConsumption > 0 && tableOpening.totalPrice == 0) {
        let amount = 0;
        return amount.toFixed(2);
      }

      let totalPrice = tableOpening.totalPrice <= tableOpening.minimumConsumption ? tableOpening.minimumConsumption : tableOpening.totalPrice;
      let amountThatRemains = totalPrice - tableOpening.amountPayedByCard - tableOpening.amountPayedByCash - tableOpening.amountChargedToRoom;
      amountThatRemains = amountThatRemains < 0 ? 0 : amountThatRemains;
      return (amountThatRemains).toFixed(2);
    },

    async selectDepartment() {
      this.isLoading = true;
      this.selectedSection = 'ALL';
      this.showOnlyOpenTables = false;
      this.$store.dispatch("setTablesWithOpenings", [])
      if (this.$route.name == 'open-tables' && this.typeOfTableOpenings == 'TABLES') {
        let currentOpenTables = [];
        let allTables = [];

        let apiResponses2 = await Promise.all([StoreApi.getTableOpeningsForRestaurant(this.selectedDepartmentId, this.tableTypesToFetchOpenings, this.authToken, this.jwt), StoreApi.getTablesWithOpeningsForRestaurant(this.store.id, this.selectedDepartmentId)]).then(data => {
          return {
            tableOpenings: data[0],
            tablesWithOpenings: data[1]
          }
        });

        let selectedDepartment = this.restaurantsWithTableOpenings.find(restaurantWithOpenings => restaurantWithOpenings.id == this.selectedDepartmentId);
        currentOpenTables = apiResponses2.tableOpenings;
        allTables = apiResponses2.tablesWithOpenings;
        this.$store.dispatch("setTablesWithOpenings", apiResponses2.tablesWithOpenings)

        let closedAndOpenTables = [];
        for (let table of allTables) {
          let openTableOpening = currentOpenTables.find(tableOpening => tableOpening.table.id == table.id);
          if (openTableOpening) {
            closedAndOpenTables.push(openTableOpening);
          } else {
            let closedTableOpening = {
              alias: "",
              amountCanceled: 0,
              amountChargedToRoom: 0,
              amountOfCardReceipts: 0,
              amountOfCashReceipts: 0,
              amountOfOnCreditReceipts: 0,
              amountOfSelfDeliveryReceipts: 0,
              amountOfVat: 0,
              amountPayedByCard: 0,
              amountPayedByCash: 0,
              amountTreated: 0,
              closingDate: null,
              currentlyOpen: false,
              dailyTableOpeningNumber: 0,
              id: null,
              minimumConsumption: 0,
              nameOfUser: null,
              openingDate: null,
              paymentType: null,
              restaurant: selectedDepartment,
              table: table,
              totalDailyOpeningNumber: 0,
              totalPrice: 0
            }
            closedAndOpenTables.push(closedTableOpening);
          }
        }
        this.tableOpenings = closedAndOpenTables;

      } else {
        this.tableOpenings = await StoreApi.getTableOpeningsForRestaurant(this.selectedDepartmentId, this.tableTypesToFetchOpenings, this.authToken, this.jwt);
      }
      this.isLoading = false;
    },

    async openTableOrders(tableOpening) {
      //this is made for the pull to refresh not to work
      //on the webview while the modal is open if the screen was at the top
      this.scrollDownPixels(8)
      this.ordersConcatedNote = "";
      this.ordersOfSelectedTableOpening = [];
      this.tempReceiptsOfSelectedTableOpening = [];
      this.finalReceiptsOfSelectedTableOpening = [];
      this.selectedTableOpening = tableOpening;
      if (tableOpening.id == null) {
        this.$store.dispatch("openTableOrdersModal");
      } else {
        this.loadingOrders = true;
        this.errorWhileLoadingOrders = false;
        this.$store.dispatch("openTableOrdersModal");
        let apiResponses = await Promise.all([OrderApi.getOrdersByTableOpening(tableOpening.id, this.selectedDepartmentId, this.authToken, this.jwt), StoreApi.fetchTempReceiptsForTableOpening(tableOpening.id, this.selectedDepartmentId, this.authToken, this.jwt), StoreApi.fetchFinalReceiptsForTableOpening(tableOpening.id, this.selectedDepartmentId, this.authToken, this.jwt)]).then(data => data);
        if (apiResponses[0].error || apiResponses[1].error || apiResponses[2].error) {
          this.errorWhileLoadingOrders = true;
        } else {
          let commentOfAllOrders = "";
          apiResponses[0].forEach(order => {
            if (order.comment != null && order.comment.trim().length > 0)
              if (commentOfAllOrders == "") {
                commentOfAllOrders = commentOfAllOrders + order.comment;
              } else {
                commentOfAllOrders = commentOfAllOrders + ", " + order.comment;
              }
          });
          this.ordersConcatedNote = commentOfAllOrders;
          this.ordersOfSelectedTableOpening = apiResponses[0];
          this.tempReceiptsOfSelectedTableOpening = apiResponses[1];
          this.finalReceiptsOfSelectedTableOpening = apiResponses[2];
        }
        this.loadingOrders = false;
      }

    },

    scrollDownPixels(pixels) {
      try {
        window.scrollTo(0, window.scrollY + pixels);
      } catch (error) {
        console.log("Could not scroll");
      }
    },

    setSelectedTableOpening(tableOpeningUpdated) {
      let index = this.tableOpenings.findIndex(tableOpening => tableOpening.id === tableOpeningUpdated.id);
      if (index != -1) {
        this.tableOpenings.splice(index, 1, tableOpeningUpdated);
      }
      this.selectedTableOpening = tableOpeningUpdated;
    },

    async updateSelectedTableOpening(tableOpening) {
      const index = this.tableOpenings.findIndex(opening => opening.id === tableOpening.id);
      if (index != -1) {
        this.tableOpenings.splice(index, 1, tableOpening);
      }
      await this.openTableOrders(tableOpening);
    },

    async loadTablesScreen() {
      this.isLoading = true;
      let storeId = this.$router.currentRoute.params.storeId;
      let tableId = this.$router.currentRoute.params.tableId;
      if (this.$route.params.store) {
        this.store = this.$route.params.store;
      } else if (!this.store) {
        this.store = await StoreApi.getStoreById(storeId);
      }

      if (this.store.hotel && this.hotelRooms.length == 0) {
        let hotelRooms = await StoreApi.getListOfRooms(this.store.id, false, null, false);
        this.hotelRooms = hotelRooms;
      }
      this.$store.dispatch("setTableId", tableId)

      if (this.$route.name == 'open-rooms') {
        let apiResponses = await Promise.all([StoreApi.getRestaurantsWithRoomOpenings(this.store.id, this.authToken, this.jwt), StoreApi.getHotelRoomSections(this.store.id)]).then(data => {
          return {
            restaurantsWithOpenings: data[0],
            sections: data[1]
          }
        });
        this.restaurantsWithTableOpenings = apiResponses.restaurantsWithOpenings;
        this.sections = apiResponses.sections;
      } else {
        let apiResponses = await Promise.all([StoreApi.getRestaurantsWithTableOpenings(this.store.id, this.authToken, this.jwt), StoreApi.getTableSections(this.store.id, false)]).then(data => {
          return {
            restaurantsWithOpenings: data[0],
            sections: data[1]
          }
        });
        this.restaurantsWithTableOpenings = apiResponses.restaurantsWithOpenings;
        this.sections = apiResponses.sections;
      }

      this.selectedDepartmentId = this.selectedDepartmentId == 0 ? this.restaurantsWithTableOpenings[0].id : this.selectedDepartmentId;

      if (this.$route.name == 'open-tables' && this.typeOfTableOpenings == 'TABLES') {
        let currentOpenTables = [];
        let allTables = [];

        if (this.tablesWithOpenings.length == 0) {
          let apiResponses2 = await Promise.all([StoreApi.getTableOpeningsForRestaurant(this.selectedDepartmentId, this.tableTypesToFetchOpenings, this.authToken, this.jwt), StoreApi.getTablesWithOpeningsForRestaurant(this.store.id, this.selectedDepartmentId)]).then(data => {
            return {
              tableOpenings: data[0],
              tablesWithOpenings: data[1]
            }
          });
          currentOpenTables = apiResponses2.tableOpenings;
          allTables = apiResponses2.tablesWithOpenings;
          this.$store.dispatch("setTablesWithOpenings", apiResponses2.tablesWithOpenings)
        } else {
          currentOpenTables = await StoreApi.getTableOpeningsForRestaurant(this.selectedDepartmentId, this.tableTypesToFetchOpenings, this.authToken, this.jwt);
          allTables = this.tablesWithOpenings;
        }

        let selectedDepartment = this.restaurantsWithTableOpenings.find(restaurantWithOpenings => restaurantWithOpenings.id == this.selectedDepartmentId);
        let closedAndOpenTables = [];
        for (let table of allTables) {
          let openTableOpening = currentOpenTables.find(tableOpening => tableOpening.table.id == table.id);
          if (openTableOpening) {
            closedAndOpenTables.push(openTableOpening);
          } else {
            let closedTableOpening = {
              alias: "",
              amountCanceled: 0,
              amountChargedToRoom: 0,
              amountOfCardReceipts: 0,
              amountOfCashReceipts: 0,
              amountOfOnCreditReceipts: 0,
              amountOfSelfDeliveryReceipts: 0,
              amountOfVat: 0,
              amountPayedByCard: 0,
              amountPayedByCash: 0,
              amountTreated: 0,
              closingDate: null,
              currentlyOpen: false,
              dailyTableOpeningNumber: 0,
              id: null,
              minimumConsumption: 0,
              nameOfUser: null,
              openingDate: null,
              paymentType: null,
              restaurant: selectedDepartment,
              table: table,
              totalDailyOpeningNumber: 0,
              totalPrice: 0
            }
            closedAndOpenTables.push(closedTableOpening);
          }
        }
        this.tableOpenings = closedAndOpenTables;

      } else {
        this.tableOpenings = await StoreApi.getTableOpeningsForRestaurant(this.selectedDepartmentId, this.tableTypesToFetchOpenings, this.authToken, this.jwt);
      }

      if (this.restaurantsWithTheirTables.length == 0 || this.adminAccounts.length == 0) {
        let restaurantIds = this.restaurantsWithTableOpenings.map(restaurant => restaurant.id);
        let apiResponses3 = await Promise.all([StoreApi.getTablesOfRestaurants(storeId, restaurantIds, this.authToken, this.jwt), UserApi.getAdminAccounts(restaurantIds, this.authToken, this.jwt)]).then(data => {
          return {
            restaurantsWithTables: data[0],
            adminAccounts: data[1]
          }
        });
        let restaurantsWithTables = apiResponses3.restaurantsWithTables;
        this.$store.dispatch("setRestaurantsWithTheirTables", restaurantsWithTables)
      }

      let serviceName = {
        GRE: {
          name: this.$route.name == 'open-rooms' ? "Ανοιχτά δωμάτια" : "Ανοιχτά τραπέζια"
        },
        ENG: {
          name: this.$route.name == 'open-rooms' ? "Open rooms" : "Open tables"
        },
      };
      this.$store.dispatch("setServiceName", serviceName);
      this.isLoading = false;
      this.setTitle();
    },

    setTitle() {
      try {
        document.title = this.title;
      } catch (error) {
        console.log("Could not set title");
      }
    }
  },
};
</script>

<style scoped>
#tables-screen {
  margin: 0px;
  box-sizing: border-box;
  padding: 0px;
  overflow-x: hidden;
  background: #fcfcfc;
  min-height: 100vh;
  z-index: 0;
  overflow-x: hidden !important;
}

#banner {
  position: relative;
  width: 100vw;
  height: 55vh;
  min-height: 220px;
}

#banner-image {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#store-info {
  position: absolute;
  top: 15%;
  right: 5%;
  width: 30%;
  min-width: 400px;
  background: white;
  border-radius: 18px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.36), 2px 2px 4px rgba(0, 0, 0, 0.48);
}

#store {
  cursor: unset;
}

.tables-container {
  min-height: 600px !important;
}

#select-department-container {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin-bottom: 1vh;
  border-bottom: 1px solid rgba(116, 135, 150, 0.2);
  padding: 7.5px 0 7.5px 0;
  color: black;
}

.label-and-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  justify-content: flex-start;
  margin-top: 8px;
  margin-left: 8px;
}

#sections-container {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  scrollbar-width: none;
  margin-top: 9px;
  margin-left: 8px;
}

.section-selection-button {
  padding: 5px 5px 5px 5px;
  border-radius: 4px;
  cursor: pointer;
  color: #198b4a;
  background-color: #ffffff;
  border: 1px solid #198b4a;
  font-size: 13px;
  font-weight: 600;
  margin-top: 0px;
  margin-right: 6px;
  white-space: nowrap;
}

.selected-section {
  color: #ffffff !important;
  background-color: #198b4a !important;
}

.department-title {
  font-size: 15px;
  font-weight: 700;
}

.open-tables-details-title {
  font-size: 15px;
  font-weight: 700;
  white-space: nowrap;
}

#add-new-order-button {
  padding: 2px 5px 3px 5px;
  border-radius: 6px;
  cursor: pointer;
  font-size: 14px;
  color: white;
  background-color: #f4a330;
  white-space: nowrap;
  margin-left: 6px;
}

.switch {
  margin-left: 4px;
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  width: 30px !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

.search-tables-input {
  background-color: white;
  font-size: 14px;
  border: 1px solid;
  border-color: rgb(183, 183, 183);
  padding: 2px 0px 2px 4px;
  border-radius: 4px;
  height: 25px;
  margin-left: 5px;
  width: 100px;
}

.label {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

select {
  display: flex;
  align-self: center;
  justify-self: center;
  -webkit-appearance: auto !important;
  box-shadow: inset 0px 0px 2px rgba(116, 135, 150, 0.2),
    inset 1px 1px 2px rgba(116, 135, 150, 0.36);
  border-radius: 6px;
  margin-bottom: 0.5rem;
  margin-left: 7px;
  padding: 2px;
  color: #198b4a !important;
  font-weight: bold;
  background: #f7f7f7;
}
@media screen and (max-width: 800px) {
  select {
    font-size: 14.5px;
  }
}
@media screen and (max-width: 300px) {
  select {
    font-size: 12px;
  }
}

option {
  color: #198b4a !important;
  font-weight: 600;
}

@keyframes slideFromBottom {
  from {
    transform: translateY(45vh);
  }

  to {
    transform: translateY(0);
  }
}

#table-items {
  margin-left: 6px;
  margin-right: 2px;
  margin-top: 20px;
  margin-bottom: 5vh;
  width: 85vw;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  align-items: center;
  animation: slideFromBottom 0.15s forwards linear;
}

#no-open-tables-message-container {
  margin-top: 20px;
  margin-bottom: 5vh;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  align-items: center;
  justify-content: center;
}

#no-open-tables-message-container > h5 {
  width: 85%;
  font-weight: 600;
  font-size: 15px;
  text-align: center;
}

.table-opening {
  flex: 50%;
  max-width: 47%;
  border: 1px solid;
  border-radius: 10px;
  border-color: #b1b1b1;
  box-shadow: 0px 0px 4px 0px #888888;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  padding-left: 4px;
  padding-right: 4px;
  padding-top: 4px;
}

.table-opening-table-header {
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 4px;
  color: #10703a;
}

.dark-table-header {
  color: #003340 !important;
}

.table-opening-section-header {
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 4px;
  color: #003340;
}

.table-closed-header {
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 4px;
  color: #003340;
}

.table-opening-table-total {
  color: #414141;
  margin-top: 6px;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 0px;
}

.table-opening-remaining-total {
  color: #414141;
  margin-top: 0px;
  font-size: 13px;
  font-weight: 700;
  margin-bottom: 4px;
}

.table-opening-table-date {
  font-size: 11px;
  font-weight: 600;
  color: #585858;
  margin-bottom: 4px;
}

#upper-bar {
  position: absolute;
  top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;

  padding-right: 1%;
}

#profile-and-cart {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 48px;
}

#language-select {
  position: absolute;
  right: 200px;
  top: 8px;
  cursor: pointer;
}

.language-position-in-login {
  right: 159px !important;
  z-index: 202;
}

#enter-prompt {
  position: absolute;
  top: 8px;
  right: 88px !important;
  z-index: 201;
}

#profile-dropdown {
  position: absolute;
  top: 0px;
  right: 88px !important;
  z-index: 201;
}

#back-to-services {
  position: fixed;
  left: 80px;
  box-shadow: 3px 4px 8px rgba(0, 0, 0, 0.32), 3px 4px 8px rgba(0, 0, 0, 0.46);
}

#logo-container {
  background: white !important;
  /* height: 45px; */
  margin-left: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  justify-content: center;
  padding: 0px 16px 0px 12px;
  border-radius: 12px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.24), 2px 2px 4px rgba(0, 0, 0, 0.36);
}

#logo {
  cursor: pointer;
  height: 40px;
  width: auto;
}

#link-to-landing {
  text-decoration: none;
}

.just-closed-table {
  background-color: #f9d876;
}

.open-table {
  background-color: #a4fc9d;
}

.blue-table {
  background-color: #66d9f6;
}

.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 204;
  opacity: 1;
  transition: 0.67s;
}

.hide {
  visibility: hidden;
  display: none;
}

.custom-footer-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#loading-mask {
  position: fixed;
  top: 0;
}

@media screen and (max-width: 1018px) {
  #language-select {
    left: unset;
    top: 5px !important;
    right: 20px;
    cursor: pointer;
  }

  .language-position-in-login {
    right: 20px !important;
    z-index: 202;
  }

  .no-bottom-margin {
    margin-bottom: 0px !important;
  }

  #profile-dropdown {
    right: 20px !important;
  }

  .profile-dropdown-with-lang-select {
    top: 58px !important;
  }

  .profile-dropdown-without-lang-select {
    top: 2px !important;
  }

  #enter-prompt {
    right: 20px !important;
    margin: 0;
  }

  .enter-prompt-with-lang-select {
    top: 56px !important;
  }

  .enter-prompt-without-lang-select {
    top: 5px !important;
  }

  #back-to-services {
    left: 20px;
  }

  #logo-container {
    min-width: 95px !important;
    justify-content: flex-start;
    margin-left: 20px;
  }

  #logo {
    height: 33px;
  }

  #banner {
    height: 40vh;
  }

  #store-info {
    top: 165px;
    width: 90%;
    left: 5%;
    min-width: 200px;
    max-height: 112px;
  }

  #table-items {
    width: 101vw;
    margin-bottom: 9vh;
  }

  #upper-bar {
    top: 15px;
    height: unset;
  }
}

@media screen and (max-width: 350px) {
  #store-info {
    top: 140px;
  }
}

@media screen and (max-width: 370px) {
  .open-tables-details-title {
    font-size: 13px;
  }
}

@media screen and (max-width: 1018px) {
  #banner {
    height: 290px !important;
  }
}

@media screen and (max-width: 530px) {
  #banner {
    height: 280px !important;
  }
}

@media screen and (max-width: 350px) {
  #banner {
    height: 250px !important;
  }
}

@media screen and (max-width: 330px) {
  #banner {
    height: 235px !important;
  }
}
</style>
